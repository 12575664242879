import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-body pt-0 pb-0 p-5" }
const _hoisted_5 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PolicyLink = _resolveComponent("PolicyLink")!
  const _component_PolicyStatus = _resolveComponent("PolicyStatus")!
  const _component_RowCol = _resolveComponent("RowCol")!

  return (_ctx.view.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, [
            _createVNode(_component_PolicyLink, {
              "policy-id": _ctx.view.id,
              "policy-number": _ctx.view.policyNumber
            }, null, 8, ["policy-id", "policy-number"]),
            _createVNode(_component_PolicyStatus, {
              status: _ctx.view.status.code
            }, null, 8, ["status"])
          ])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_RowCol, {
              label: "Client",
              value: _ctx.view.client?.id,
              type: "CUSTOM"
            }, {
              custom: _withCtx(() => [
                _createElementVNode("a", {
                  href: "javascript:void(0);",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadData(_ctx.view.client.id, 'COMPANY')))
                }, _toDisplayString(_ctx.view.client.name), 1)
              ]),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_RowCol, {
              label: "Product",
              value: _ctx.view?.product?.id,
              type: "CUSTOM"
            }, {
              custom: _withCtx(() => [
                _createElementVNode("a", {
                  href: "javascript:void(0);",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.loadData(_ctx.view?.product?.id.toString(), 'PRODUCT')))
                }, _toDisplayString(_ctx.view?.product?.name), 1)
              ]),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_RowCol, {
              label: "Insured",
              value: _ctx.view.insureds,
              type: "CUSTOM"
            }, {
              custom: _withCtx(() => [
                _createElementVNode("a", {
                  href: "javascript:void(0);",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadData(_ctx.view.insureds[0]?.id, 'INSURED')))
                }, _toDisplayString(_ctx.view.insureds[0]?.person?.name), 1)
              ]),
              _: 1
            }, 8, ["value"]),
            _createVNode(_component_RowCol, {
              label: "DOB",
              value: _ctx.view.insureds[0]?.person?.dob,
              type: "DATE"
            }, null, 8, ["value"]),
            _createVNode(_component_RowCol, {
              label: "Effective",
              value: _ctx.view.effectiveDt,
              "show-if-null": true,
              type: "DATE"
            }, null, 8, ["value"]),
            _createVNode(_component_RowCol, {
              label: "Termination",
              value: _ctx.view.terminationDt,
              "show-if-null": true,
              type: "DATE"
            }, null, 8, ["value"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}