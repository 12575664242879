
import { defineComponent } from "vue";
import { LoadPanel } from "@/core/composite/composite";
import RowCol from "@/components/base/common/RowCol.vue";
import PolicyStatus from "@/views/policy/PolicyStatus.vue";
import PolicyLink from "@/views/policy/PolicyLink.vue";

export default defineComponent({
  name: "PolicyCard",
  components: {PolicyLink, PolicyStatus, RowCol},
  props: {
    view: {}
  },
  setup() {
    return {
      ...LoadPanel(),
    }
  }
})
